import * as React from 'react'
import Article from '../components/article'
import Seo from '../components/seo'
import CtaArticle from '../components/ctaArticle'

const PropertyOwnership = () => {
  return (
    <>
      <Seo
        pageTitle={'Property Ownership'}
        pageDescription={`If you are concerned on where one of your biggest assets is going to
    go to upon your death, contact us and we shall discuss this further.
    We shall also ensure your Will is also written in the correct way to
    reflect your wishes.`}
      />

      <Article>
        <h1>Property Ownership</h1>

        <p className='lead'>There are 3 ways you can own a property</p>

        <h2>
          <small>1. </small>Solely
        </h2>
        <p>
          Where you are the only owner of your home or any other properties you
          own and if you were married then the property would pass directly to
          your Spouse / Civil partner.
        </p>

        <h2>
          <small>2. </small>Jointly
        </h2>
        <p>
          This is where the property is owned by all the owners. If one of the
          owners dies, the property would automatically pass to the surviving
          owner i.e. Surviving Spouse / Civil Partner-if they then remarried the
          property will fall outside of your estate. If you owned a property
          with your partner and you passed away, the property would go to your
          partner at 100%, even if you have your own children and a Will.
        </p>

        <h2>
          <small>3. </small>Tenants in Common
        </h2>
        <p>
          Although this sounds like you are a tenant, do not worry you are not.
          You are an owner of a distinctive share of the property. This way of
          owning your home or other properties protects your share in event of
          divorce or separation. Your share of the property will not
          automatically pass to the surviving owner. Your Will can stipulate
          where your share of the property is to be distributed upon your death.
        </p>

        <p>
          If you are concerned on where one of your biggest assets is going to
          go to upon your death, contact us and we shall discuss this further.
          We shall also ensure your Will is also written in the correct way to
          reflect your wishes.
        </p>
        <CtaArticle />
      </Article>
    </>
  )
}

export default PropertyOwnership
